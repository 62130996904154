<template>
	<div id="miniGoodsList">
		<page-goods-list :is-shop="0" :is_purchase="1"></page-goods-list>
	</div>
</template>

<script>
	import pageGoodsList from '@/components/layout/goods/page-goods-list.vue'
	export default{	
		components:{
			pageGoodsList
		},
		data(){
			return{
			}
		},
		mounted(){
			
		},
		methods:{
		
		}
		
	}
</script>

<style>
</style>
